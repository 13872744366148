
import React, { useContext, useEffect, useState } from "react";
import AuthContext from '../store/auth-context';
import AxiosContext from '../store/axios-context';
import { useHistory } from 'react-router';

function Login({ axiosInstance }) {
  const history = useHistory();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [formError, setFormError] = useState(null);
  const axiosCtx = useContext(AxiosContext);
  const authCtx = useContext(AuthContext);

  const usernameChangeHandler = (event) => {
    setUsername(event.target.value);
  };

  const passwordChangeHandler = (event) => {
    setPassword(event.target.value);
  };

  useEffect(() => {
    if (authCtx.isLoggedIn == true) {
      history.replace('/job-list');
    }
  }, []);

  const submitHandler = (event) => {
    event.preventDefault();

    setFormError(null);

    // if (!usernameIsValid || !passwordIsValid) {
    //   return;
    // }

    // Call login API
    const params = {
      email: username,
      password: password,
    };

    // Call login API
    const formData = new FormData();
    formData.append("email", username);
    formData.append("password", password);

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };


    axiosCtx.instance.defaults.withCredentials = true;
    axiosCtx.instance.post(`login`, formData, config)
      .then((response) => {
        authCtx.login(response.data.data.token);
        authCtx.userRole(response.data.data.type.type);
        authCtx.userName(response.data.data.name);
        history.replace('/job-list');
      })
      .catch((error) => {
        console.log(error.response.data);
        setFormError(error.response.data);
        // setLoading(false);
      });
  };
  return (
    <>
      <div className="login-page h-100 d-flex align-items-center justify-content-center" >


        <div className="login-wrap">
          <form className="form " method="POST" action="" onSubmit={submitHandler}>
            <div className="card card-login">
              <div className="card-header ">
                <div className="card-header text-center">
                  <svg width="213" height="58" viewBox="0 0 213 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M64.418 10.556V13.57H58.126V16.826H62.834V19.752H58.126V26H54.364V10.556H64.418ZM74.2713 26L71.0593 20.17H70.1573V26H66.3953V10.556H72.7093C73.9266 10.556 74.9606 10.7687 75.8113 11.194C76.6766 11.6193 77.3219 12.206 77.7473 12.954C78.1726 13.6873 78.3853 14.5087 78.3853 15.418C78.3853 16.4447 78.0919 17.3613 77.5053 18.168C76.9333 18.9747 76.0826 19.5467 74.9533 19.884L78.5173 26H74.2713ZM70.1573 17.508H72.4893C73.1786 17.508 73.6919 17.3393 74.0293 17.002C74.3813 16.6647 74.5573 16.188 74.5573 15.572C74.5573 14.9853 74.3813 14.5233 74.0293 14.186C73.6919 13.8487 73.1786 13.68 72.4893 13.68H70.1573V17.508ZM84.5088 13.57V16.694H89.5468V19.598H84.5088V22.986H90.2068V26H80.7468V10.556H90.2068V13.57H84.5088ZM96.4112 10.556V26H92.6492V10.556H96.4112ZM109.389 15.44C109.111 14.9267 108.707 14.538 108.179 14.274C107.666 13.9953 107.057 13.856 106.353 13.856C105.136 13.856 104.161 14.2593 103.427 15.066C102.694 15.858 102.327 16.9213 102.327 18.256C102.327 19.6787 102.709 20.7933 103.471 21.6C104.249 22.392 105.312 22.788 106.661 22.788C107.585 22.788 108.363 22.5533 108.993 22.084C109.639 21.6147 110.108 20.94 110.401 20.06H105.627V17.288H113.811V20.786C113.533 21.7247 113.056 22.5973 112.381 23.404C111.721 24.2107 110.878 24.8633 109.851 25.362C108.825 25.8607 107.666 26.11 106.375 26.11C104.85 26.11 103.486 25.78 102.283 25.12C101.095 24.4453 100.164 23.514 99.4894 22.326C98.8294 21.138 98.4994 19.7813 98.4994 18.256C98.4994 16.7307 98.8294 15.374 99.4894 14.186C100.164 12.9833 101.095 12.052 102.283 11.392C103.471 10.7173 104.828 10.38 106.353 10.38C108.201 10.38 109.756 10.8273 111.017 11.722C112.293 12.6167 113.137 13.856 113.547 15.44H109.389ZM129.271 10.556V26H125.509V19.642H119.657V26H115.895V10.556H119.657V16.606H125.509V10.556H129.271ZM143.097 10.556V13.57H139.005V26H135.243V13.57H131.151V10.556H143.097Z" fill="#3A57E8" />
                    <path d="M71.854 33.556V49H68.092V39.738L64.638 49H61.602L58.126 39.716V49H54.364V33.556H58.808L63.142 44.248L67.432 33.556H71.854ZM84.1733 46.272H78.4093L77.4853 49H73.5473L79.1353 33.556H83.4913L89.0793 49H85.0973L84.1733 46.272ZM83.2053 43.368L81.2913 37.714L79.3993 43.368H83.2053ZM104.596 49H100.834L94.542 39.474V49H90.78V33.556H94.542L100.834 43.126V33.556H104.596V49ZM116.937 46.272H111.173L110.249 49H106.311L111.899 33.556H116.255L121.843 49H117.861L116.937 46.272ZM115.969 43.368L114.055 37.714L112.163 43.368H115.969ZM133.796 38.44C133.517 37.9267 133.114 37.538 132.586 37.274C132.072 36.9953 131.464 36.856 130.76 36.856C129.542 36.856 128.567 37.2593 127.834 38.066C127.1 38.858 126.734 39.9213 126.734 41.256C126.734 42.6787 127.115 43.7933 127.878 44.6C128.655 45.392 129.718 45.788 131.068 45.788C131.992 45.788 132.769 45.5533 133.4 45.084C134.045 44.6147 134.514 43.94 134.808 43.06H130.034V40.288H138.218V43.786C137.939 44.7247 137.462 45.5973 136.788 46.404C136.128 47.2107 135.284 47.8633 134.258 48.362C133.231 48.8607 132.072 49.11 130.782 49.11C129.256 49.11 127.892 48.78 126.69 48.12C125.502 47.4453 124.57 46.514 123.896 45.326C123.236 44.138 122.906 42.7813 122.906 41.256C122.906 39.7307 123.236 38.374 123.896 37.186C124.57 35.9833 125.502 35.052 126.69 34.392C127.878 33.7173 129.234 33.38 130.76 33.38C132.608 33.38 134.162 33.8273 135.424 34.722C136.7 35.6167 137.543 36.856 137.954 38.44H133.796ZM144.064 36.57V39.694H149.102V42.598H144.064V45.986H149.762V49H140.302V33.556H149.762V36.57H144.064ZM169.694 33.556V49H165.932V39.738L162.478 49H159.442L155.966 39.716V49H152.204V33.556H156.648L160.982 44.248L165.272 33.556H169.694ZM176.161 36.57V39.694H181.199V42.598H176.161V45.986H181.859V49H172.399V33.556H181.859V36.57H176.161ZM198.118 49H194.356L188.064 39.474V49H184.302V33.556H188.064L194.356 43.126V33.556H198.118V49ZM211.954 33.556V36.57H207.862V49H204.1V36.57H200.008V33.556H211.954Z" fill="#3A57E8" />
                    <path d="M19.7271 13.4973C20.3459 13.1537 21.0421 12.9733 21.75 12.9733C22.4579 12.9733 23.1541 13.1537 23.7729 13.4973L39.4292 22.1932C39.7538 22.3737 40.0242 22.6377 40.2125 22.9578C40.4007 23.278 40.5 23.6426 40.5 24.014V41.1703C40.4998 41.9133 40.301 42.6427 39.924 43.283C39.5471 43.9233 39.0058 44.4512 38.3563 44.8119L23.7729 52.9161C23.1541 53.2598 22.4579 53.4401 21.75 53.4401C21.0421 53.4401 20.3459 53.2598 19.7271 52.9161L5.14375 44.8119C4.49449 44.4514 3.9534 43.9238 3.57649 43.2839C3.19958 42.644 3.00055 41.915 3 41.1723V24.014C2.99998 23.6426 3.09926 23.278 3.28753 22.9578C3.47581 22.6377 3.74624 22.3737 4.07083 22.1932L19.7292 13.4973H19.7271Z" stroke="#3A57E8" stroke-width="4.16667" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M43.4838 17.5042L40.2194 5.72483C40.0903 5.25927 39.8446 4.91566 39.5054 4.72642C39.1663 4.53717 38.7448 4.50853 38.2809 4.6432L38.2819 4.64376L26.5423 8.05133" stroke="#3A57E8" stroke-width="4.16667" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M3 22.789L21.75 33.2057L3 22.789ZM21.75 33.2057L40.5 22.789L21.75 33.2057ZM21.75 33.2057V54.039Z" fill="white" />
                    <path d="M21.75 33.2057V54.039M3 22.789L21.75 33.2057L3 22.789ZM21.75 33.2057L40.5 22.789L21.75 33.2057Z" stroke="#3A57E8" stroke-width="4.16667" stroke-linejoin="round" />
                    <path d="M12.375 27.9974L31.125 17.5807L12.375 27.9974ZM9.25 33.889L15.5 37.3724Z" fill="white" />
                    <path d="M9.25 33.889L15.5 37.3724M12.375 27.9974L31.125 17.5807L12.375 27.9974Z" stroke="#3A57E8" stroke-width="4.16667" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  {/* <h3 className="header text-center mb-3 font-weight-bold">Login</h3> */}
                </div>
              </div>
              <div className="card-body  px-5">
                <div className="form-group mb-4">

                  <input type="text" id="username" value={username} onChange={usernameChangeHandler} className="form-control" placeholder="Email" />
                  {formError &&
                    formError.errors &&
                    formError.errors.email && (
                      <p className="text-danger small pt-2">
                        {formError.errors.email.join('. ')}
                      </p>
                    )}
                </div>
                <div className="form-group">

                  <input type="password" id="password" onChange={passwordChangeHandler} placeholder="Password" className="form-control" />
                  {formError &&
                    formError.errors &&
                    formError.errors.password && (
                      <p className="text-danger small pt-2">
                        {formError.errors.password.join('. ')}
                      </p>
                    )}
                  {formError &&
                    formError.auth_errors && (
                      <p className="text-danger small pt-2">
                        {formError.auth_errors}
                      </p>
                    )}
                </div>

              </div>
              <div className="card-footer  px-5 pb-4">
                <button
                  type="submit"
                  className="btn btn-primary btn-block mb-3"
                >
                  Login
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Login;
