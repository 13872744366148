import React from "react";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@mui/material/Autocomplete';

const Dropdown = props => {

    return (
        <FormControl variant="outlined" className="w-100">
            <Autocomplete
                disabled={props.disabled}
                value={props.value || null}
                size="small"
                onChange={(e, value) => props.onChange(e, value)}
                id={props.id}
                options={props.options ?? []}
                getOptionLabel={(option) => (props.label1) ? option[props.label] + '-' + option[props.label1] : option[props.label]}
                renderInput={(params) => <TextField {...params} label={props.placeholder} />}
            />
            {
                props.formError && (
                    <p className="text-danger small pt-2">
                        {props.formError.join('. ')}
                    </p>
                )}
        </FormControl>
    );
};

export default Dropdown;

