import React from "react";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Stack from '@mui/material/Stack';

const InputField = props => {
    const onFocusOut = (e) => {
        if (typeof (props.onBlur) === 'function') {
            props.onBlur(e);
        } else {
            return null;
        }
    }
    const onChange = (e) => {
        if (typeof (props.onChange) === 'function') {
            props.onChange(e);
        } else {
            return null;

        }
    }
    return (
        <FormControl variant="outlined" className="w-100">
            <Stack component="form" noValidate spacing={3}>
                <TextField
                    disabled={props.disabled}
                    size="small"
                    type={props.type}
                    id={props.id}
                    label={props.label}
                    value={props.value}
                    multiline={props.multiline}
                    rows={props.rows}
                    onChange={onChange}
                    onBlur={onFocusOut}
                />
            </Stack>
            {
                props.formError && (
                    <p className="text-danger small pt-2">
                        {props.formError.join('. ')}
                    </p>
                )}
        </FormControl>
    );
};

export default InputField;

